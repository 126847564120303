import {PageContainer, ProLayout} from "@ant-design/pro-components";
import {FontSizeOutlined,FontColorsOutlined,
    LogoutOutlined,
    UnlockOutlined,
    UserOutlined,
    MoonOutlined,
    SunOutlined,
} from "@ant-design/icons";
import {ConfigProvider, Dropdown, message, theme} from "antd";
import React, {useEffect, useState} from "react";
import store from "store";
import dLogo from "../images/game.svg"
import cookie from "react-cookies";
import {useNavigate} from "react-router";
import {showIcon} from "./MyUploadIcon"
import {NavLink} from "react-router-dom";
import { useAppContext } from '../AppContaxt';
import {ReactComponent as AutoThemeIcon} from "../images/auto-theme.svg"


interface IProps {
    children: React.ReactNode;
    siderCollapse?: boolean;
}

const MyProLayout = (props: IProps) => {

    // const [isLoading, setIsLoading] = useState(true)
    // const location = useLocation()

    const {appState, updateAppState} = useAppContext();
    // const [test1, setTest1] = useState(0);
    // const [themeState, setThemeState] = useState(appState?.themeState || "light");
    // const [themeSelected, setThemeSelected] = useState(appState?.themeSelected || "light");

    const [pathname, setPathname] = useState("");
    // const [menus, setMenus] = useState<any>({});
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [selectKeys, setSelectKeys] = useState<string[]>([]);
    const [breadCrumbs, setBreadCrumbs] = useState<any[]>([]);
    const [siderCollapse, setSiderCollapse] = useState(false)

    const login_info = store.get('login_info');
    const navigate = useNavigate();
    let hashPath = window.location.hash;

    // useEffect(()=>{
    //     console.log(themeState, "changed")
    // },[themeState])

    console.log("MyProLayout => *")
    console.log(appState.menuRouter)
    const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

    useEffect(() => {
        console.log("MyProLayout => []")
        // setIsLoading(false)
        // const storeState = store.get("appState");
        // updateAppState(storeState);
        // setCurrentTheme(storeState?.themeState || "light")

        // console.log("handleInit", mediaQueryList.matches)
        // if (themeSelected === "auto") {
        //     setCurrentTheme(mediaQueryList.matches ? "dark" : "light");
        // } else {
        //     setCurrentTheme(storeState?.themeState === "dark" ? "dark" : "light");
        // }

        onClickTheme({key: appState.themeSelected})

        mediaQueryList.addEventListener('change', handleChange);
        return () => {
            mediaQueryList.removeEventListener('change', handleChange);
        };

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event: any) => {
        if (appState?.themeSelected === "auto") {
            updateAppState({themeState: event.matches ? "dark" : "light"});
        }
    };
    const onClickTheme = (e: any) => {
        let theme;
        if (e.key === "auto") {
            // const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
            console.log("handleClick", mediaQueryList.matches, appState?.themeState);
            theme = mediaQueryList.matches ? "dark" : "light";
        } else {
            theme = e.key
        }
        // setThemeState(theme);
        // setThemeSelected(e.key);
        updateAppState({themeState: theme, themeSelected: e.key});
    }
    const onClickFont = (e: any) => {
        updateAppState({fontState:  e.key});
    }
    let keys1: string[] = [];
    let keys2: string[] = [];
    let crumb: any[] = [];
    let menuRoute = appState.menuRouter.find(m => m.path === "/");
    useEffect(() => {
        console.log("MyProLayout => useEffect[props]")
        setSiderCollapse(props.siderCollapse || false);
        setPathname(hashPath.substring(1))

        keys1 = [];
        keys2 = [];
        getMenuKeys(menuRoute?.children);
        setOpenKeys(props.siderCollapse ? [] : keys1);
        setSelectKeys(keys2);
        setBreadCrumbs(crumb);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const getMenuKeys: any = (funList?: any[]) => {
        funList?.forEach((r: any) => {
            try {
                // if (r.children && r.children.length > 0 && !keys.includes(r.key)) {
                //     keys.push(r.key.toString());
                // }
                let hashPath1 = hashPath.length > 1 ? hashPath.substring(1) : hashPath;
                // console.log("hashPath1---", hashPath1.substring(r.linkPath.length, r.linkPath.length+1))
                if (r && r.linkPath && (hashPath1 === r.linkPath || hashPath1.startsWith(r.linkPath + "/"))
                ) {
                    // console.log(hashPath1.substring(1, r.linkPath.length))
                    keys2.push(r.key.toString());
                    keys1.push(r.key.toString());
                    crumb.push({title: r.name});
                }
                // if (hashPath.split("/")[1] == r.path) {
                //     keys2.push(r.key.toString());
                //     crumb.push({title: r.name});
                // }else if (hashPath.split("/")[2] == r.linkPath.split('/')[2] ) {
                //     crumb.push({title: r.name});
                // }else if (hashPath.substring(1) == r.linkPath) {
                //     keys2.push(r.key.toString());
                //     crumb.push({title: r.name});
                // }
                // r.icon = showIcon(r.iconName);
                if (r.children) {
                    getMenuKeys(r.children);
                }
            } catch (e) {
                console.log("Error in => MyProLayout getMenuKeys", e)
                navigate('/login');
                return;
            }
        })
    }

    const menuLinkUrl = (item: any) => {
        return !item.interface || item.interface === "" ? "/page404"
            : item.interface.substring(0, 1) === "/" ? item.interface
                : item.linkPath
    }

    const changePass = (e: any) => {
        console.log(e)
        navigate("/changePass")
    }
    const logout = (e: any) => {
        console.log(e)
        store.set('login_info', undefined);
        cookie.save("isLogin", "false", {path: "/"});
        message.success('已退出，请重新登录').then();
        window.setTimeout(() => {
            navigate('/login');
        }, 1000)
    }

    const avatarDropdownMenu = {
        items: [
            {
                key: 'password',
                icon: <UnlockOutlined/>,
                label: '修改密码 ',
                onClick: changePass
            },
            {
                key: 'logout',
                icon: <LogoutOutlined/>,
                label: '退出登录',
                onClick: logout
            },
        ],
    }
    const themDropdownMenu = {
        items: [
            {
                key: "light",
                icon: <SunOutlined/>,
                label: "白昼模式",
                onClick: onClickTheme
            },
            {
                key: 'dark',
                icon: <MoonOutlined/>,
                label: '暗夜模式',
                onClick: onClickTheme
            },
            {
                key: 'auto',
                icon: <AutoThemeIcon width={14} height={14}/>,
                label: '跟随系统',
                onClick: onClickTheme
            },
        ],
    }
    const fontDropdownMenu = {
        items: [
            {
                key: "f14",
                icon: <FontColorsOutlined style={{fontSize: 14}}/>,
                label: "标准",
                onClick: onClickFont
            },
            {
                key: 'f12',
                icon: <FontColorsOutlined style={{fontSize: 12}}/>,
                label: "紧凑",
                onClick: onClickFont
            },
        ],
    }

    return (
        <ConfigProvider
            theme={{
                algorithm: appState?.themeState === "dark" ?
                    appState?.fontState === "f12" ? [theme.darkAlgorithm, theme.compactAlgorithm]
                        : [theme.darkAlgorithm]
                    : appState?.fontState === "f12" ? [theme.defaultAlgorithm, theme.compactAlgorithm]
                        : [theme.defaultAlgorithm],
                token: {
                    // Seed Token，影响范围大
                    colorPrimary: "#4c9ef4",
                    colorLink: "#4c9ef4",
                    fontSize: 14,
                    // borderRadius: 2,

                    // 派生变量，影响范围小
                    // colorBgContainer: '#f0f8ff',
                },
            }}
        >
            <ProLayout
                title={"EasyGameNow"}
                logo={dLogo}
                inlineCollapsed={false}
                headerTitleRender={(logo, title) =>
                    <div style={{width: 208, marginRight: 8}}>{logo}{title}</div>}
                // token={{header: {}}}
                layout={"mix"}
                splitMenus={true}
                siderMenuType={"sub"}
                route={menuRoute}

                avatarProps={{
                    icon: <UserOutlined/>,
                    title: <>Welcome: <span className="bold" style={{marginRight: 32}}>
                        {login_info?.loginName}</span> </>,
                    render: (props, dom) =>
                        <Dropdown menu={avatarDropdownMenu}>{dom}</Dropdown>
                }}
                actionsRender={(props) => {
                    if (props.isMobile) return [];
                    if (typeof window === 'undefined') return [];
                    return [
                        <Dropdown menu={themDropdownMenu}>
                            {appState.themeSelected === "dark" ? <MoonOutlined/>
                                : appState.themeSelected === "auto" ? <AutoThemeIcon width={32} height={32}/>
                                    : <SunOutlined/>}
                        </Dropdown>,
                        <Dropdown menu={fontDropdownMenu}>
                            <FontSizeOutlined/>
                        </Dropdown>,
                    ];
                }}
                menuItemRender={(item, dom) => (
                    <NavLink to={menuLinkUrl(item)}>
                        {item.pro_layout_parentKeys && item.pro_layout_parentKeys.length > 1 &&
                            <div style={{float: "left", marginRight: 8}}>
                                {showIcon(item.iconName)}
                            </div>
                        }
                        {dom}
                    </NavLink>
                )}
                menuProps={{
                    openKeys: openKeys,
                    selectedKeys: selectKeys,
                    onOpenChange: (keys) => {
                        setOpenKeys(keys)
                    },
                    style: {marginTop: 24}
                }}
                breadcrumbProps={{items: breadCrumbs}}
                location={{pathname: pathname}}
                collapsed={siderCollapse}
                onCollapse={(e) => setSiderCollapse(e)}
            >
                {/*{appState.themeState}*/}
                <PageContainer title={false}>
                    {props.children}
                </PageContainer>
            </ProLayout>
        </ConfigProvider>
    )
}
export default MyProLayout
